<template>
    <div class="home">
        <Loading v-if="isLoading"></Loading>

        <!-- SLIDER -->
        <Slider></Slider>
        <!-- SLIDER -->

        <!-- CUERPO  -->
        <div class="container p-4">
            <div class="row">
                <!-- MENU -->
                <div class="col-lg-3" style="border: solid 1px #dae2e6">
                    <h3 class="pt-2">Categorias</h3>

                    <div class="accordion" id="accordionExample">
                        <div class="menu-titular" v-for="category in catreverse" :key="category.id">
                            <div :id="'head' + category.id" style="border-bottom: 1px solid #dae2e6">
                                <h2 class="mb-0">
                                    <a class="menu-font-titular btn-categorias" type="button" data-toggle="collapse"
                                        :data-target="'#collapse' + category.id" aria-expanded="true"
                                        :aria-controls="'collapse' + category.id">
                                        {{ category.name }}
                                        <i class="pull-right bi bi-chevron-down mt-1"></i>
                                    </a>
                                </h2>
                            </div>

                            <div :id="'collapse' + category.id" class="collapse" :aria-labelledby="'head' + category.id"
                                data-parent="#accordionExample">
                                <div>
                                    <ul>
                                        <li v-for="item in category.subcategories" :key="item.id">
                                            <router-link :to="'/productos/' + item.id"
                                                class="text-decoration-none menu-font-titular btn-categorias">{{
                                                item.name }}</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- CATALOGO -->
                <div class="col-lg-9">
                    <div class="row p-4" style="margin-bottom: 25px">
                        <div class="col-lg-12 text-center">
                            <strong>NUESTROS PRODUCTOS</strong>
                            <hr />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3" v-for="product in pageOfItems.slice(0, 12)" :key="product.id">
                            <div class="card mb-4 product-wap rounded-0">
                                <div class="card rounded-0">
                                    <div v-if="product.imagen == null">
                                        <img class="card-img rounded-0 img-fluid" :src="product.gallery[0].url" />
                                    </div>
                                    <div v-if="product.imagen !== null">
                                        <img class="card-img rounded-0 img-fluid"
                                            :src="'https://tm.platform.cl/public/img/producto/' + product.imagen" />
                                    </div>
                                    <div
                                        class="card-img-overlay rounded-0 product-overlay d-flex align-items-center justify-content-center">
                                        <ul class="list-unstyled">
                                            <li>
                                                <a class="btn btn-success text-white mt-2" data-toggle="modal"
                                                    :data-target="'#product' + product.id"><i
                                                        class="far fa-eye"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card-body text-center">
                                    <a href="" class="h3 text-decoration-none">
                                        <p class="producto-text"> {{ product.title }}</p>
                                    </a>
                                    <hr />

                                    <button type="button" class="btn btn-danger text-white" data-toggle="modal"
                                        :data-target="'#product' + product.id">
                                        <i class="bi bi-bag-fill"></i> Cotizar
                                    </button>
                                </div>
                            </div>

                            <!-- Modal -->
                            <div class="modal fade" :id="'product' + product.id" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">
                                                {{ product.title }}
                                            </h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <img class="card-img rounded-0 " style="width:80%" :src="'https://tm.platform.cl/public/img/producto/' +
                                                        product.imagen
                                                        " />

                                                </div>
                                                <div class="col-lg-8">
                                                    <p class="mt-2" v-html="product.description"></p>
                                                    {{ product.sku }}<br />
                                                    <p v-html="product.size"></p>
                                                    <p v-html="product.color"></p>
                                                    <div class="text-right">
                                                        <a href="https://api.whatsapp.com/send?phone=56993267388&text=Hola%2C%20me%20gustaria%20cotizar"
                                                            target="_black" class="btn btn-dark btn-sm mr-2"><i
                                                                class="bi bi-whatsapp"></i> Cotizar por Whatsapp</a>
                                                        <button v-on:click="isHidden = !isHidden"
                                                            class="btn btn-warning"><i class="bi bi-cart"></i> Cotizar
                                                            Online</button>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12" v-if="!isHidden">

                                                    <Form v-bind:producto="product.id" v-bind:names="product.title">
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Modal -->
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <jw-pagination class="col-lg-8" :items="productos" @changePage="onChangePage"
                            :labels="customLabels" :pageSize="12" :maxPages="10"
                            :disableDefaultStyles="true"></jw-pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- CUERPO -->

        <Footer></Footer>
    </div>
</template>

<script>
import axios from "axios";
import Loading from "../components/Loading.vue";
import Footer from "../components/FooterPage.vue";
import Slider from "../components/Slider.vue";
import {
    url,
    key
} from "../config/main";
import Form from "../components/Quatation.vue";
const customLabels = {
    first: "Primero",
    last: "Ultimo",
    previous: "Anterior",
    next: "Siguiente",
};

export default {
    name: "Home",
    components: {
        Footer,
        Slider,
        Form,
        Loading,
    },
    data() {
        return {
            categories: [],
            category: [],
            productos: [],
            product: [],
            search: "",
            pageOfItems: [],
            customLabels,
            isLoading: true,
            catreverse: [],
            isHidden: true
        };
    },
    created() {
        this.getCategory();
        this.getProducts();
    },
    methods: {
        getProducts() {
            const data = {
                method: "get",
                url: `${url}/api/products/all`,
                headers: {
                    Accept: "application/json",
                },
                timeout: 10000,
            };
            axios(data)
                .then((responses) => {
                    this.productos = responses.data.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        onChangePage(pageOfItems) {
            // update page of items
            this.pageOfItems = pageOfItems;
        },

        getCategory() {
            let config = {
                method: "get",
                url: `${url}/api/v1/categories`,
                headers: {
                    Accept: "application/json",
                    Authorization: key,
                },
                timeout: 10000,
            };

            axios(config)
                .then((response) => {
                    this.categories = response.data.data;
                    this.catreverse = this.categories.reverse();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },

    computed: {
        filteredList2: function () {
            return this.productos.filter((product) => {
                return product.title.toLowerCase().includes(this.search.toLowerCase());
            });
        },
    },

    mounted() {
        setTimeout(() => {
            this.isLoading = false;
        }, 1000);
    },
};
</script>

<style>
.menu-font-titular {
    color: #000;
    text-decoration: none;
    font-weight: 900;
    font-size: 13px !important;
    margin-bottom: 5px;
}

.menu-font-titular:hover {
    color: #000;
    text-decoration: none;
}

.btn-categorias {
    padding: 5px;
    color: #000;
    border-radius: 5px;
}

.btn-categorias:hover {
    background-color: #b0161e;
    padding: 5px;
    color: #fff;
    border-radius: 5px;
}

.btn-pagination {
    background-color: #b0161e;
    color: #fff;
    margin: 1px;
}

.btn-pagination:hover {
    background-color: #b0161e;
}

.btn-pagination:active {
    background-color: #b0161e;
}

.btn-pagination::selection {
    background-color: #b0161e;
}

.pagination ul {
    width: 100% !important;
    text-align: center;
    padding: 10px;
}

/* - Pagination component container (ul element) */
.pagination li {
    text-align: center;
    background-color: #b0161e;
}

/* All list items in the pagination component */
.pagination li a {
    text-align: center;
    background-color: #b0161e;
    color: #fff;
}

/* All pagination links including first, last, previous and next */
.pagination li.page-number {
    text-align: center;
    background-color: #b0161e;
}

/* All page numbers (1, 2, 3 etc) pagination elements  */

.pagination li.page-number .active {
    text-align: center;
    background-color: #b0161e;
}

/* All page numbers (1, 2, 3 etc) pagination elements  */
.pagination li.first {
    display: none;
}

/* The 'First' pagination element */

.pagination li.last {
    display: none;
}

/* The 'Last' pagination element */
.pagination li.previous {
    text-align: center;
    background-color: #b0161e;
}

/* The 'Previous' pagination element */

.pagination li.next {
    text-align: center;
    background-color: #b0161e;
}

/* The 'Next' pagination element */
.page-item.active .page-link {
    background-color: #212934;
    border: none !important;
    margin: 1px;
}

.producto-text {
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>
