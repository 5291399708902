<template>
  <!-- Start Banner Hero -->
  <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li
        data-target="#carouselExampleCaptions"
        data-slide-to="0"
        class="active"
      ></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          src="img/slider01.jpg"
          style="opacity: 0.4"
          class="d-block"
          alt="..."
        />
        <div class="carousel-caption d-none d-md-block">
          <h5 class="tt-slider">Linea Ecológica</h5>
          <p class="p-slider">
            Regala productos ecológicos y refleja el cuidado que tiene tu
            empresa con el medio ambiente.
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <img
          src="img/slider02.jpg"
          style="opacity: 0.4"
          class="d-block"
          alt="..."
        />
        <div class="carousel-caption d-none d-md-block">
          <h5 class="tt-slider text-center">Artículos Electrónicos</h5>
          <p class="p-slider text-center">
            Sorprende a tus colaboradores con artículos computacionales y
            electrónicos personalizados con tu marca.
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <img
          src="img/slider03.jpg"
          style="opacity: 0.4"
          class="d-block"
          alt="..."
        />
        <div class="carousel-caption d-none d-md-block">
          <h5 class="tt-slider">Bolígrafos Personalizados</h5>
          <p class="p-slider">
            Contamos con cientos de modelos de bolígrafos personalizados,
            metálicos, ecológicos y muchas más opciones.
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <img
          src="img/slider04.jpg"
          style="opacity: 0.4"
          class="d-block"
          alt="..."
        />
        <div class="carousel-caption d-none d-md-block">
          <h5 class="tt-slider">Regalos Personalizados</h5>
          <p class="p-slider">
            Contamos con miles de opciones diferentes para tus regalos
            personalizados y corporativos.
          </p>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-target="#carouselExampleCaptions"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-target="#carouselExampleCaptions"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </button>
  </div>
  <!-- End Banner Hero -->
</template>

<script>
export default {};
</script>

<style>
.tt-slider {
  font-size: 48px;
  color: #b0161e;
  font-weight: bold;
  text-align: right;
}

.p-slider {
  font-size: 24px;
  color: #000;
  font-weight: normal;
  text-align: right;
}
</style>
