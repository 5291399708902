<template>
  <div>
    <form
      class="p-4 card text-dark"
      v-on:submit.prevent="createQuatation(Elproducto)"
    >
      <div class="row mb-2 p-2">
        <div class="col-lg-4">Nombre Completo</div>
        <div class="col-lg-8">
          <input
            type="text"
            v-model="name"
            class="form-control"
            placeholder="EJ: Juan Carlos"
            required
          />
        </div>
      </div>
      <div class="row mb-2 p-2">
        <div class="col-lg-4">Email</div>
        <div class="col-lg-8">
          <input
            type="email"
            v-model="email"
            class="form-control"
            placeholder="EJ: juancarlos@gmail.com"
            required
          />
        </div>
      </div>
      <div class="row mb-2 p-2">
        <div class="col-lg-4">Celular</div>
        <div class="col-lg-8">
          <input
            type="text"
            v-model="phone"
            class="form-control"
            placeholder="EJ: +56 95555 7735"
            required
          />
        </div>
      </div>
      <div class="row mb-2 p-2">
        <div class="col-lg-4">Dirección:</div>
        <div class="col-lg-8">
          <input
            type="text"
            v-model="location"
            class="form-control"
            placeholder="EJ: Av. Son Carlos de Apoquindo #7774"
            required
          />
        </div>
      </div>

      <div class="row mb-2 p-2">
        <div class="col-lg-4">Tipo de Impresion:</div>
        <div class="col-lg-8">
          <select class="form-control" v-model="type">
            <option value="" disabled="" selected="">
              Por favor seleccione
            </option>
            <option value="1 color">1 color</option>
            <option value="2 colores">2 colores</option>
            <option value="Fullcolor">Full Color</option>
            <option value="Laser">Laser</option>
            <option value="Bordados">Bordados</option>
            <option value="Sin Impresion">Sin Impresión</option>
          </select>
        </div>
      </div>

      <div class="row mb-2 p-2">
        <div class="col-lg-4">Unidades a Cotizar:</div>
        <div class="col-lg-8">
          <input
            type="text"
            placeholder="50"
            v-model="units"
            class="form-control"
          />
        </div>
      </div>

      <div class="row mb-2 p-2">
        <div class="col-lg-4"></div>
        <div class="col-lg-8 text-right">
          <button class="btn btn-danger">Cotizar Ahora</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { url, key } from "../config/main";
import emailjs from "emailjs-com";

export default {
  props: ["producto", "names"],
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      cant: "50",
      location: "",
      units: "",
      type: "",

      Elproducto: this.producto,
      Elnombre: this.names,
    };
  },
  methods: {
    showAlert() {
      // Use sweetalert2
      this.$swal({
        title: "Formulario Enviado",
        text: "Gracias por completar el formulario, a la brevedad una de nuestras ejecutivas te contactara",
        icon: "success",
        confirmButtonText: "Cerrar",
      });
    },
    createQuatation(producto) {
      let data = JSON.stringify({
        name: this.name,
        cant: "50",
        email: this.email,
        phone: this.phone,
        units: this.units,
        type: this.type,
        location: this.location,
        product_id: producto,
      });

      let config = {
        method: "post",
        url: `${url}/api/v1/quates`,
        headers: {
          Accept: "application/json",
          Authorization: key,
          "Content-Type": "application/json",
        },
        timeout: 10000,
        data: data,
      };

      axios(config)
        .then((response) => {
          this.showAlert();
          const mensaje =
            " | Cliente: " +
            response.data.data.name +
            " | Email: " +
            response.data.data.email +
            " | Telefono: " +
            response.data.data.phone +
            " | Producto: " +
            this.Elnombre +
            " |  Cantidad: " +
            response.data.data.units;

          emailjs.send(
            "service_44awv6i",
            "template_iubqp0o",
            {
              to_name: "esteban@quiebre.cl",
              message: mensaje,
              reply_to: "estebancortes1993@gmail.com",
            },
            "user_nZjvy6GwdjjUsj91TfJVG"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
