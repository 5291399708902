import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// axios
import axios from "axios";
import VueAxios from "vue-axios";

// main.js
import VueSweetalert2 from "vue-sweetalert2";

const options = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674",
};

// css
import "sweetalert2/dist/sweetalert2.min.css";

// register jw pagination component globally
import JwPagination from "jw-vue-pagination";
Vue.component("jw-pagination", JwPagination);

Vue.use(VueSweetalert2, options);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
