<template>
  <div id="loading">
    <img class="img" src="https://i.ibb.co/rppyMJr/logo.png" alt="" />
    <div class="svg-loader">
      <svg class="svg-container" height="100" width="100" viewBox="0 0 100 100">
        <circle class="loader-svg bg" cx="50" cy="50" r="45"></circle>
        <circle class="loader-svg animate" cx="50" cy="50" r="45"></circle>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style>
#loading {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 9999 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: #fff;
  text-align: center;
  color: #000;
}
.img {
  position: relative;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 100px;
}

.texts {
  position: relative;
  margin: auto;
  margin-top: 0px;
  color: #000;
}

.line {
  position: relative;
  top: 10%;
  font-family: "Montserrat", sans-serif;
  width: 25em;
  margin: 0 auto;
  color: #000;
  border-right: 2px solid rgba(255, 255, 255, 0.75);
  font-size: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
}
/*Animation*/
.anim-typewriter {
  animation: typewriter 2s steps(36) 1s 1 normal both,
    blinkTextCursor 500ms steps(36) infinite normal;
}
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 16em;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}

.svg-loader {
  display: flex;
  position: relative;
  align-content: space-around;
  justify-content: center;
}
.loader-svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  fill: none;
  stroke-width: 5px;
  stroke-linecap: round;
  stroke: rgba(255, 255, 255);
}
.loader-svg.bg {
  stroke-width: 8px;
  stroke: #000;
}

.animate {
  stroke-dasharray: 242.6;
  animation: fill-animation 2s cubic-bezier(1, 1, 1, 1) 0s infinite;
}

@keyframes fill-animation {
  0% {
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 141.3;
    stroke-dashoffset: 141.3;
  }
  100% {
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 282.6;
  }
}
</style>
